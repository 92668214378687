export const getDataByFetch = async (url,flag = false, encoding = false, authorization = false) => {
    try {
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
        if(!!encoding) myHeaders.append("Accept-Encoding", encoding);
        if(!!authorization) myHeaders.append("Authorization", authorization);
        const response = await fetch(`${url}`, {
            method: 'GET',
            headers: myHeaders,
        });
        const data = await response.json();
        if(!flag){
            const { items = [] } = data.item || data;
            return items;
        } else {            
            return data;
        }
        
    } catch (error) {
        return false;
    }
}

export const getPingByFetch = async (url) => {
    try {
        const response = await fetch(`${url}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        const data = await response.json(); 
        return data;
        
    } catch (error) {
        return {
            ping: "error",
            error: error.message
        };
    }
}

export const getBucketsHeaders = async (flag = false) => {
    const url_base = process.env.NEXT_PUBLIC_FRONTEND_API;
    const urls = {
        opinion: `${url_base}contents/v1/detail/opinion?type=bucket&field=slug`,
        liveblogs: `${url_base}contents/v1/detail/lieveblogs-lx?type=bucket&field=slug`,
        ultimomento: `${url_base}contents/v1/latest/?type=lilanews&section=10260&limit=12`,
        urgent: `${url_base}contents/v1/menus/urgente`,
    };
    const [opinion, liveblogs, ultimomento, urgent] = await Promise.all([
        (flag == true) ? getDataByFetch(`${urls.opinion}`) : new Promise((resolve) => resolve(false)),
        getDataByFetch(`${urls.liveblogs}`),
        (flag == true) ? getDataByFetch(`${urls.ultimomento}`) : new Promise((resolve) => resolve(false)),
        getDataByFetch(`${urls.urgent}`,false),
    ]);    
    return {
        opinion,
        liveblogs,
        ultimomento,
        urgent,
    }
}

export const getBucketById = async (idBucket) => {
    const content = await getDataByFetch(`${process.env.NEXT_PUBLIC_PAGEGROWLER_FRONTEND_API}${idBucket}?type=bucket`, true);
    return content?.content ?? [];
}

export const getBucketBySlug = async (slug) => {
    const content = await getDataByFetch(`${process.env.NEXT_PUBLIC_PAGEGROWLER_FRONTEND_API}${slug}?type=bucket&field=slug`, true);
    return content?.content ?? [];
}

export const getBucketByIdFromOle = async (idBucket) => {
    const content = await getDataByFetch(`${process.env.NEXT_PUBLIC_FRONTEND_API_OLE}contents/v1/detail/${idBucket}?type=bucket&field=slug`);
    return content;
}

export const getBucketElleMagazines = async (idBucket) => {
    const content = await getDataByFetch(`http://api.lilax.agea.com.ar/E1S4G4ycq/contents/v1/detail/q7dCRE4N4?type=bucket`);
    return content;
}